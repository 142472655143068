import { BaseOptions, LocalizedBaseOptions } from "./form.types";
import { CustomerFieldLabel, getGenderOptions } from "../../utils/customer.util";
import { FormSpec, InputSpecSelect, localized } from "../../form-specs";
import { FormWidgetSpec, Widget } from "../";
import { getBaseOptionsSpecs, localizedOptions } from "./";
import { getFormFieldVariable, getFormFieldsDescription, isFormFieldRequired } from "./form.util";
import { getI18nLocaleObject, getI18nLocaleString } from "../../i18n";

import { CMSProvidedProperties } from "../../containers/cmsProvider.types";
import { SelectOption } from "../../form-specs/formSpec.types";
import { WidgetGroup } from "../widget.enum";
import namespaceList from "../../i18n/namespaceList";
import { readOnlyField } from "../../components/utils";

/* jscpd:ignore-start */
interface LocalizedWidgetOptions extends LocalizedBaseOptions {
    placeholder: string;
    options: string;
    defaultOption: string;
}

interface WidgetOptions extends BaseOptions<LocalizedWidgetOptions> {
    localizedSelectOptions: LocalizedWidgetOptions[];
    enableReadOnly?: boolean;
}
/* jscpd:ignore-end */

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "form-select-widget-options",
    name: getI18nLocaleObject(namespaceList.pluginForm, "selectWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.pluginForm, "selectWidgetOptions"),
    /* jscpd:ignore-start */
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            ...getBaseOptionsSpecs(),
                            localized({
                                variable: "localizedSelectOptions",
                                tabContent: [
                                    {
                                        type: "text",
                                        variable: "options",
                                        label: getI18nLocaleObject(namespaceList.admin, "options"),
                                    },
                                    {
                                        type: "text",
                                        variable: "defaultOption",
                                        label: getI18nLocaleObject(namespaceList.pluginForm, "defaultOption"),
                                    },
                                    {
                                        type: "text",
                                        variable: "placeholder",
                                        label: getI18nLocaleObject(namespaceList.pluginForm, "placeholder"),
                                    },
                                ],
                            }),
                            readOnlyField("enableReadOnly"),
                        ],
                    ],
                },
            ],
        },
    ],
    /* jscpd:ignore-end */
};

export const selectWidget: FormWidgetSpec<WidgetOptions> = {
    id: "form-select",
    type: "form",
    widgetGroup: WidgetGroup ? WidgetGroup.INPUT : 6,
    name: getI18nLocaleObject(namespaceList.pluginForm, "selectWidget"),
    description: getI18nLocaleObject(namespaceList.pluginForm, "selectWidgetDesc"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        fieldId: "",
        required: false,
        localizedSelectOptions: [],
        localized: [],
        dynamicFieldId: "",
        enableReadOnly: false,
    }),
    async toInputSpec(widget: Widget<WidgetOptions>, context: CMSProvidedProperties): Promise<InputSpecSelect<any, any>> {
        const { localizedSelectOptions, enableReadOnly } = widget.options;
        const { currentLocale, site } = context;
        const localeOptions = localizedOptions(context, widget.options) || { label: "" };
        const { label } = localeOptions;
        const labelLocale = label;
        const localeSelectOptions = localizedSelectOptions.find((l) => l.locale === currentLocale.locale);
        const placeholder = localeSelectOptions ? localeSelectOptions.placeholder : undefined;
        const defaultOption =
            localeSelectOptions && localeSelectOptions.defaultOption
                ? {
                      value: localeSelectOptions.defaultOption,
                      label: localeSelectOptions.defaultOption,
                  }
                : null;
        let options: Array<SelectOption<string>> = [];
        if (localeSelectOptions?.options) {
            options = localeSelectOptions.options.split(",").map((val) => ({
                value: val,
                label: val,
            }));
        }
        if (defaultOption) {
            options.push(defaultOption);
        }
        if (widget.options.dynamicFieldId === CustomerFieldLabel.sex.toLocaleLowerCase() && !localeSelectOptions?.options) {
            options = getGenderOptions(currentLocale, site);
        }
        return {
            type: "select",
            variable: getFormFieldVariable(widget.options),
            label: labelLocale,
            required: await isFormFieldRequired(widget.options, context),
            default: defaultOption ? defaultOption.value : null,
            options: widget.options,
            optionList: options,
            placeholder,
            message: (localeOptions as LocalizedWidgetOptions).message,
            enableReadOnly,
        };
    },
    instanceDescription({ widget }): string {
        const formFieldDescriptionParams = {
            /* jscpd:ignore-start */
            fieldId: widget.options.fieldId,
            dynamicFieldId: widget.options.dynamicFieldId,
            useforBookingsModule: widget.options.useforBookingsModule,
            defaultDescription: getI18nLocaleString(namespaceList.pluginForm, "selectWidgetDesc"),
            /* jscpd:ignore-end */
        };
        return getFormFieldsDescription(formFieldDescriptionParams);
    },
};
